<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					variant="success"
					class="float-right"
					@click="exportUnshippedOrders()">
					<i
						class="fas fa-file-download"
						aria-hidden="true" />
					{{ translate('export') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template #form>
				<div class="row">
					<div
						:class="'col-md-4'"
						class="col-sm-4 col-12">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									:value=" '' ">
									{{ translate('all') }}
								</option>
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th
							class="border-top-0">
							{{ translate('order_number') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('order_date') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('app_form_status') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('username') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('original_order') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_to_name') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_address') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('phone') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('ship_method') }}
						</th>
						<th
							class="border-top-0">
							{{ translate('order_status') }}
						</th>
						<th
							v-for="(item, index) in productMapping"
							:key="index"
							class="border-top-0">
							{{ item }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle text-center">
							{{ item.attributes.order_number }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.order_date.date).format('DD/MM/YYYY') }}
						</td>
						<td class="align-middle">
							{{ item.attributes.app_form_status }}
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle text-center">
							{{ item.attributes.original_order }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_to_name }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_address }}
						</td>
						<td class="align-middle">
							{{ item.attributes.phone }}
						</td>
						<td class="align-middle">
							{{ item.attributes.ship_method }}
						</td>
						<td class="align-middle">
							{{ item.attributes.order_status }}
						</td>
						<td
							v-for="(productMap, indexMap) in productMapping"
							:key="indexMap"
							class="align-middle text-center">
							{{ item.attributes[productMap] }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
	</div>
</template>
<script>

import { UnshippedOrders } from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DataFilter from '@/components/DataFilter';
import { DATE_RANGES as dateRanges } from '@/settings/Dates';
import Order from '@/util/Order';

export default {
	name: 'Orders',
	messages: [
		UnshippedOrders,
	],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams],
	data() {
		return {
			orders: new Order(),
			download: new Order(),
			dateRanges,
		};
	},
	computed: {
		loading() {
			return !!this.orders.data.loading;
		},
		data() {
			try {
				const { data } = this.orders.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		productMapping() {
			try {
				const { meta } = this.orders.data.response.data;
				return meta.product_mapping;
			} catch (error) {
				return [];
			}
		},
	},
	mounted() {
		this.dateRange = this.$route.query.dateRange ?? '';
		this.getOrders(this.getQuery());
	},
	methods: {
		getOrders(options = {}) {
			this.orders.getUnshippedOrders(false, options);
		},
		getQuery() {
			const { query } = this.$route;
			return { ...query, ...this.filters };
		},
		getDataFiltered() {
			this.$router.push({ name: 'UnshippedOrders', query: this.getQuery() }).catch(() => {});
		},
		exportUnshippedOrders() {
			this.download.getUnshippedOrders(true, this.getQuery()).then(() => {
				const { response } = this.download.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				const filename = `${this.translate('unshipped_orders')}.csv`;
				link.setAttribute('download', filename);
				this.$el.appendChild(link);
				link.click();
			});
		},
	},
};
</script>
